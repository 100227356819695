const isincognito = () => {
  try {
    localStorage.setItem("isincognito", "checking");
    localStorage.removeItem("isincognito");
    return false;
  } catch (error) {
    return true;
  }
}

export const getStorage = () => {
  if (isincognito()) {
    return sessionStorage;
  } else {
    return localStorage;
  }
}
