import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ContentContextProvider } from './store/content-context';
import { getStorage } from './services/Storage';

import './index.css';
import './index.scss';
import 'antd/dist/reset.css';
// import your language files
import lang_en from "./locales/en.json";
import lang_es from "./locales/es.json";
import lang_hi from "./locales/hi.json";
import lang_fr from "./locales/fr.json";
import lang_uk from "./locales/uk.json";
import lang_ru from "./locales/ru.json";
import lang_gu from "./locales/gu.json";

import App from "./App";
import reportWebVitals from "./reportWebVitals";


i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // default language
  resources: {
    en: { common: lang_en },
    hi: { common: lang_hi },
    es: { common: lang_es },
    fr: { common: lang_fr },
    uk: { common: lang_uk },
    ru: { common: lang_ru },
    gu: { common: lang_gu },
  },
});


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ContentContextProvider>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider >
      </ContentContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
