import { createContext, useState } from 'react';

const ContentContext = createContext({
  content: null,
  loggedInEmail: '',
  contentPrivacy: null,
  localSiccuraFileUrl: null,
  apiURL: "https://sic-rest.wemetgroup.com/index.php/",
  socketIOUrl: "https://api-community.sandaholdings.com",
  onlyOfficeUrl: "https://api-community.sandaholdings.com",
  storeContent: function (contentData) { },
  setLoggedInEmail: function (email) { },
  setAppDomain: function (url) { },
  setContentPrivacy: function (privacy) { }
});

export function ContentContextProvider(props) {

  const [activeContent, setActiveContent] = useState();
  const [activeEmail, setActiveEmail] = useState();
  const [appDomain, setAppDomain] = useState();
  const [localFileUrl, setLocalFileUrl] = useState();
  const [editMode, setEditMode] = useState(false);
  const [syncMode, setSyncMode] = useState(false);
  const [apiURL, setApiURL] = useState();
  const [socketIOUrl, setSocketIOUrl] = useState();
  const [onlyOfficeUrl, setOnlyOfficeUrl] = useState();
  const [activeContentPrivacy, setActiveContentPrivacy] = useState();

  function storeContentHandler(contentData) {
    setActiveContent(contentData);
  }

  function setLoggedInEmailHandler(email) {
    setActiveEmail(email);
  }

  function setApiURLHandler(url) {
    setApiURL(url);
  }

  function setAppDomainHandler(url) {
    // console.log("setting app domain: ", url);
    setAppDomain(url === "" ? "https://sic-rest.wemetgroup.com/index.php" : url);
  }

  function setLocalFileUrlHandler(url) {
    // console.log("setting app local file: ", url);
    setLocalFileUrl(url);
  }

  function setSocketIOUrlHandler(url) {
    setSocketIOUrl(url);
  }

  function setOnlyOfficeUrlHandler(url) {
    setOnlyOfficeUrl(url);
  }

  function storeContentPrivacyHandler(privacy) {
    setActiveContentPrivacy(privacy)
  }

  function editModeHandler(mode) {
    setEditMode(mode);
  }

  function syncModeHandler(mode) {
    setSyncMode(mode)
  }


  const context = {
    content: activeContent,
    loggedInEmail: activeEmail,
    localSiccuraFileUrl: localFileUrl,
    contentPrivacy: activeContentPrivacy,
    apiBaseURL: appDomain,
    editMode: editMode,
    syncMode: syncMode,
    syncModeHandler: syncModeHandler,
    editModeHandler: editModeHandler,
    storeContent: storeContentHandler,
    setLoggedInEmail: setLoggedInEmailHandler,
    setAppDomain: setAppDomainHandler,
    setLocalFileUrl: setLocalFileUrlHandler,
    setApiURL: setApiURLHandler,
    setSocketIOUrl: setSocketIOUrlHandler,
    setOnlyOfficeUrl: setOnlyOfficeUrlHandler,
    setContentPrivacy: storeContentPrivacyHandler
  };

  return (
    <ContentContext.Provider value={context}>
      {props.children}
    </ContentContext.Provider>
  );
}

export default ContentContext;
